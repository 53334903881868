import React, {Component} from 'react';
import { Container, Row, Col, Progress} from 'reactstrap';
import Fade from 'react-reveal/Fade';

class About extends Component {
  
  printSkill = (skillName, skillLevel) => {
    return(
        <Row className="skillRow">
          <Col xs="6"><span className="arrow">➤</span><span className="skillName">{skillName}</span></Col>
          <Col xs="6"><Progress color="success" value={skillLevel}/></Col>
        </Row>
        );
  }
  
  render() {
  return (
    <Fade left>
    <div className="componentContainer" id="aboutDiv">
        <Container>
        <h2>About me</h2>
        <Row>
          <Col xs="6">
          <p className={"text-left"}>
          My name is John Hernandez and I recently graduated from Mount Royal University attaining a degree in Bachelor of Computer information Systems with a minor in Business. I also participated in the Co-operative Education program which has given me both practical and experiential learning with various companies in the Software Development sector. I have consistently been a part of the Dean’s Honour Roll (7 times) and have been included in the President’s Honour Roll. 
          <br></br>
          <br></br>
          My passion lies in coding and problem solving. I love the challenge it offers and the sense of accomplishment I am left with when a bug is resolved or when a feature is successfully implemented. I also love the collaboration involved in programming – whether it is collaborating with other software developers or a business unit within a corporation. Over the last four years of University, I have dedicated time outside of the lab and schoolwork towards tutoring Programming and SQL concepts to other students within Mount Royal University’s Peer Tutor Program. 
          <br></br>
          <br></br>
          As I begin my journey as a new graduate, I look forward to applying knowledge I attained in University in real life experiences, but also am eager to learn new concepts.
          <br></br>
          <br></br>
          I love learning!
          </p>
          </Col>
          <Col>
        {/*
        {this.printSkill('Java','95')}
        {this.printSkill('SQL','100')}
        {this.printSkill('HTML5','100')}
        {this.printSkill('JavaScript','95')}
        {this.printSkill('PHP','85')}
        {this.printSkill('ReactJS','85')}
        {this.printSkill('CSS3','75')}
        {this.printSkill('Arduino','65')}
        {this.printSkill('Unity','25')}
        */}

        </Col>
        </Row>
        </Container>
    </div>
    </Fade>
  );
  }
}


export default About;
